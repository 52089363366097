.App {
  padding: 0.5rem;
  width: 100%;
  height: 100vh;
  background-color: #282828;
}

.navBar-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 10%;
  background-color: #383838;
  box-sizing: border-box;
  /* margin-bottom: 0.5rem; */
  /* border: 0.1rem solid #282828; */
}

.navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #383838;
}

.logo {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  padding: 0.3rem;
  background-color: #383838;
  box-sizing: border-box;
  border-right: 0.1rem solid #282828;
  justify-content: center;
  align-items: center;
}

.logo-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo1 {
  width: 70%;
  height: auto;
}

.sign {
  display: flex;
  flex-direction: column;
  width: 15%;
  height: 100%;
  padding: 0.3rem;
  background-color: #383838;
  box-sizing: border-box;
  border-right: 0.1rem solid #282828;
  color: white;
  justify-content: center;
  align-items: center;
}

.sign-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 60%;
}

.p {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
}

.slovan {
  width: 30%;
  height: auto;
}

.record {
  display: flex;
  flex-direction: column;
  width: 27%;
  height: 100%;
  padding: 0.3rem;
  background-color: #383838;
  box-sizing: border-box;
  /* border: 0.1rem solid #282828; */

  justify-content: center;
  align-items: center;
}

.record-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 80%;
  color: white;
}

.recording {
  width: 30%;
  height: 60%;
  background-color: #4d4d4d;
  color: white;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 160%;
  border-color: transparent;
}

li {
  color: red;
  align-items: center;
}

.check {
  color: #faff10;
}

.activ {
  color: #00ff00;
}

.network {
  display: flex;
  flex-direction: column;
  width: 9%;
  height: 100%;
  background-color: #383838;
  box-sizing: border-box;
  /* border: 0.1rem solid #282828; */
  border-right: 0.1rem solid #282828;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 80%;
}

.ingest {
  display: flex;
  flex-direction: column;
  width: 9%;
  height: 100%;
  background-color: #383838;
  box-sizing: border-box;
  border-right: 0.1rem solid #282828;
  /* border: 0.1rem solid #282828; */
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 80%;
}

.timekepeer {
  display: flex;
  flex-direction: column;
  width: 10%;
  height: 100%;
  background-color: #383838;
  box-sizing: border-box;
  border-right: 0.1rem solid #282828;
  /* border: 0.1rem solid #282828; */
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 80%;
}

.settings {
  min-width: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.click1 {
  background-color: #4d4d4d;
  width: 50%;
  height: 50%;
  border-color: transparent;
}

.content-wrapper {
  padding-top: 0.5rem;
  display: flex;
  height: 90%;
}

.game-wrapper {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  background-color: #282828;
  box-sizing: border-box;
  /* border: 0.1rem solid #282828; */
}

.game-information {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50%;
  background-color: #282828;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  /* border: 0.1rem solid #282828; */
}

.game-information-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #282828;
  box-sizing: border-box;
  margin-right: 0.5rem;

  /* border: 0.1rem solid #282828; */
}

.game {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40%;
  background-color: #383838;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0.5rem;
}

.rink {
  display: flex;
  width: 55%;
  height: 100%;
  background-color: #383838;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem;
}

.iceRink {
  width: 350px;
  height: 175px;
  /* width: 100%; */
  /* height: auto; */
}

.game-time-wrapper {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 100%;
  background-color: #383838;
  justify-content: top;
  align-items: center;
  box-sizing: border-box;
  color: white;
}

.game-time {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 15%;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
}

.timer-wrapper {
  display: flex;
  width: 80%;
  height: 55%;
  background-color: #4d4d4d;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
}

.left-side {
  background-color: #4d4d4d;
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.time {
  background-color: #4d4d4d;
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.5em;
  font-family: Roboto, sans-serif;
}

.right-side {
  background-color: #4d4d4d;
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.up {
  width: 100%;
  height: 50%;
  margin-bottom: 0.15rem;
  background-color: #4d4d4d;
  border: 0.1rem solid #2bb5e9;
}

.down {
  width: 100%;
  height: 50%;
  margin-top: 0.15rem;
  background-color: #4d4d4d;
  border: 0.1rem solid #2bb5e9;
}

.game-buttons {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 30%;
  background-color: #383838;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  box-sizing: none !important;
}

.play {
  display: flex;
  width: 50%;
  height: 90%;
  margin-right: 0.15rem;
  background-color: #00ff00;
  justify-content: center;
  align-items: center;
  border-color: transparent;
  color: black;
}
.MuiIcon-fontSizeLarge {
  font-size: 10rem !important;
}
.stop {
  display: flex;
  width: 50%;
  height: 90%;
  margin-left: 0.15rem;
  background-color: #ff0000;
  justify-content: center;
  align-items: center;
  border-color: transparent;
  color: black;
}

.game-score-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  background-color: #383838;
  box-sizing: border-box;
  /* border: 0.1rem solid #383838; */
  /* border-top: 0.1rem solid #282828; */
  padding: 0.2rem;
  justify-content: center;
  align-items: center;
}

.teams-wrapper {
  width: 100%;
  height: 50%;
  background-color: #383838;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: Roboto, sans-serif;
  justify-content: center;
  align-items: center;
}

.teams-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.teams-header-home {
  margin: 0;
  padding: 0;
  padding-left: 0.3rem;
}

.teams-header-guest {
  margin: 0;
  padding: 0;
  padding-right: 0.3rem;
}

.teams-controls-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.teams-home-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  /*box-sizing: border-box;*/
  background-color: #383838;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.teams-home {
  background-color: #4d4d4d;
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  color: white;
  font-size: large;
  font-weight: 300;
  border-color: transparent;
}

.teams-mark-wrapper {
  min-width: 12%;
  height: 100%;
  background-color: #383838;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /* border: 0.2rem; */
  /* padding-bottom: 0.2rem; */
}

.teams-marks-free {
  width: 100%;
  height: 18%;
}

.team-mark1 {
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 0.15rem;
  background-color: #4d4d4d;
  border-color: transparent;
}

.team-mark2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 0.15rem;
  background-color: #4d4d4d;
  color: #2bb5e9;
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  border-color: transparent;
}

.teams-guest-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  /* box-sizing: border-box;*/
  background-color: #383838;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.teams-guest {
  background-color: #4d4d4d;
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  color: white;
  font-size: large;
  font-weight: 300;
  border-color: transparent;
}

.score-wrapper {
  width: 100%;
  height: 50%;
  background-color: #383838;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.score-home-wrapper {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  height: 100%;
  background-color: #383838;
}

.score-home-players-wrapper {
  width: 45%;
  height: 100%;
  background-color: #383838;
  box-sizing: border-box;
  padding: 0.3rem;
  font-size: large;
  color: white;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.score-home-players-name {
  width: 100%;
  height: 25%;
  background-color: #383838;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-home-players-all {
  width: 100%;
  height: 75%;
  background-color: #383838;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.score-home-players-content {
  width: 70%;
  height: 100%;
  background-color: #383838;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.players-diode-wrapper {
  background-color: #4d4d4d;
  width: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* border: 0.1rem solid #4d4d4d; */
  box-sizing: border-box;
}

.score-diode {
  background-color: rgba(0, 255, 0, 1);
  width: 100%;
  height: 100%;
  /* height: 16%; */
  box-sizing: border-box;
  border: 0.1rem solid #383838;
}

.score-home-players {
  background-color: #4d4d4d;
  width: 90%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 3rem;
}

.score-home-players-button {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #4d4d4d;
}

.score-home-score-wrapper {
  width: 45%;
  height: 100%;
  padding: 0.3rem;
  background-color: #383838;
  box-sizing: border-box;
  font-size: large;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.score-home-score-name {
  width: 100%;
  height: 25%;
  background-color: #383838;
  align-items: center;
  justify-content: center;
  display: flex;
}

.score-home-score-all {
  width: 100%;
  height: 75%;
  background-color: #383838;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.score-home-score {
  background-color: #4d4d4d;
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}

.score-home-score-button {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #4d4d4d;
}

.button-arrow-icon {
  color: #2bb5e9 !important;
  width: 80% !important;
  height: auto !important;
}

.score-guest-wrapper {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  height: 100%;
  background-color: #383838;
}

.score-guest-score-wrapper {
  width: 45%;
  height: 100%;
  padding: 0.3rem;
  background-color: #383838;
  box-sizing: border-box;
  font-size: large;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.score-guest-score-name {
  width: 100%;
  height: 25%;
  background-color: #383838;
  align-items: center;
  justify-content: center;
  display: flex;
}

.score-guest-score-all {
  width: 100%;
  height: 75%;
  background-color: #383838;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.score-guest-score {
  background-color: #4d4d4d;
  width: 70%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 3rem;
}

.score-guest-score-button {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #4d4d4d;
}

.score-guest-players-wrapper {
  width: 45%;
  height: 100%;
  background-color: #383838;
  box-sizing: border-box;
  /* border: 0.3rem solid #383838; */
  padding: 0.3rem;
  font-size: large;
  color: white;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.score-guest-players-name {
  width: 100%;
  height: 25%;
  background-color: #383838;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-guest-players-all {
  width: 100%;
  height: 75%;
  background-color: #383838;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.score-guest-players-content {
  width: 70%;
  height: 100%;
  background-color: #383838;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.score-guest-players {
  background-color: #4d4d4d;
  width: 90%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 3rem;
}

.score-guest-players-button {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #4d4d4d;
}

.events-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #282828;
  box-sizing: border-box;
  margin-right: 0.5rem;
  /* border: 0.1rem solid #282828; */
}

.reaction {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 15%;
  background-color: #383838;
  /* box-sizing: border-box; */
  /*border: 0.1rem solid #282828;*/
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.event {
  background: #4d4d4d;
  color: #2bb5e9;
  border: 0.1rem solid #2bb5e9;
  width: 100%;
  margin-right: 0.25rem;
  height: 90%;
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
}

.player {
  background: #4d4d4d;
  color: #2bb5e9;
  border: 0.1rem solid #2bb5e9;
  width: 100%;
  margin-left: 0.25rem;
  height: 90%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.btn-primary {
  background-color: #4d4d4d !important;
  color: #4d4d4d !important;
  max-width: 100% !important;
  height: 100% !important;
  border-color: transparent !important;
}

.others {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 10%;
  height: 90%;
  margin-left: 0.5rem;
}

.events-wrapper-content {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  height: 100%;
  background-color: #282828;
  box-sizing: border-box;
  border: 0.5rem solid #383838;
  padding: 0.5rem;
  /* justify-content: flex-start; */
  align-items: center;
}

.event-list:last-child {
  margin-bottom: 0;
}

.event-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 20%;
  /* background: linear-gradient(#e66465, #9198e5); */
  background: linear-gradient(
    to right,
    rgba(0, 209, 255, 0.4),
    #4d4d4d,
    95%,
    #4d4d4d
  );
  box-sizing: border-box;
  /* border: 0.2rem solid #282828; */
  margin-bottom: 0.5rem;
}

.event-list-start {
  display: flex;
  width: 1%;
  height: 100%;
  background-color: yellow;
}

.event-list-number {
  display: flex;
  width: 8%;
  height: 100%;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 90%;
  color: white;
  font-weight: 300;
}

.event-list-country {
  display: flex;
  width: 8%;
  height: 100%;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 90%;
  color: white;
  font-weight: 300;
}

.event-list-action {
  display: flex;
  width: 25%;
  height: 100%;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 90%;
  color: #faff10;
  font-weight: bold;
}

.event-list-time {
  display: flex;
  width: 8%;
  height: 100%;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 90%;
  color: white;
  font-weight: 300;
}

.event-list-button {
  display: flex;
  width: 50%;
  height: 100%;
  background-color: transparent;
  justify-content: space-around;
  align-items: center;
}

.eventListButton {
  display: flex;
  width: 15%;
  height: 80%;
  background-color: #4d4d4d;
  justify-content: space-around;
  align-items: center;
  border: 0.1rem solid #2bb5e9;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  background-color: #282828;
  box-sizing: border-box;
  padding-right: 0.5rem;
  /* border: 0.1rem solid #282828; */
}

.action {
  display: grid;
  /* flex-direction: row; */
  padding: 0.5rem;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(3, 1fr);
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  width: 100%;
  height: 60%;
  background-color: #383838;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  /* border: 0.1rem solid #282828; */
}

.subaction {
  display: grid;
  /*flex-direction: row;*/
  width: 100%;
  height: 40%;
  background-color: #383838;
  box-sizing: border-box;
  /* border: 0.1rem solid #282828; */
  padding: 0.5rem;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(2, 1fr);
  z-index: 10;
}

.click {
  background-color: #4d4d4d;
  color: white;
  border-color: transparent;
}

.players-wrapper {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  background-color: #282828;
  box-sizing: border-box;
  /* border: 0.1rem solid #282828; */
}

.players-navBar {
  width: 100%;
  height: 6%;
  background-color: #282828;
  display: flex;
  justify-content: center;
  align-items: center;
}

.teamsButton {
  width: 30%;
  height: 95%;
  background-color: #383838;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: large;
  border-color: transparent;
  border-left: 0.1rem solid #282828;
  border-right: 0.1rem solid #282828;
}

.players-number-content {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  background-color: #383838;
  display: flex;
  flex-direction: column;
}

.players-number-L {
  width: 100%;
  height: 20%;
  background-color: #383838;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.players-number-R {
  width: 100%;
  height: 20%;
  background-color: #323232;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end;
}

.players-number-last {
  width: 100%;
  height: 20%;
  background-color: #383838;
  display: flex;
  align-items: center;
}

.players-number-L1 {
  width: 14%;
  height: 100%;
  background-color: #383838;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.players-number-right-L1 {
  width: 14%;
  height: 100%;
  background-color: #323232;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.L1 {
  width: 95%;
  height: 95%;
  background-color: #4d4d4d;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: large;
  font-weight: 300;
  border-color: transparent;
}

.players-number-F1D1 {
  width: 14%;
  height: 100%;
  background-color: #383838;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.players-number-right-F1D1 {
  width: 14%;
  height: 100%;
  background-color: #323232;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}

.F1 {
  width: 90%;
  height: 45%;
  background-color: #4d4d4d;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: large;
  font-weight: 300;
  border-color: transparent;
}

.D1 {
  width: 90%;
  height: 45%;
  background-color: #4d4d4d;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: large;
  font-weight: 300;
  border-color: transparent;
}

.players-number {
  width: 43%;
  height: 100%;
  background-color: #383838;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.players-right-number {
  width: 43%;
  height: 100%;
  background-color: #323232;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}

.players-number-up {
  width: 98%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.players-number-down {
  width: 98%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.up2 {
  width: 30%;
  height: 90%;
  background-color: #4d4d4d;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: large;
  font-weight: 300;
  border-color: transparent;
}

.down2 {
  width: 30%;
  height: 90%;
  background-color: #4d4d4d;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: large;
  font-weight: 300;
  border-color: transparent;
}

.players-number-others {
  width: 29%;
  height: 100%;
  background-color: #383838;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  flex-direction: column;
}

.players-number-others-up {
  width: 100%;
  height: 50%;
  background-color: #383838;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 0.4rem;
  box-sizing: border-box;
}

.players-number-others-down {
  width: 100%;
  height: 50%;
  background-color: #383838;
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  box-sizing: border-box;
}

.up4 {
  width: 45%;
  height: 90%;
  background-color: #4d4d4d;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: large;
  font-weight: 300;
  border-color: transparent;
}

.down4 {
  width: 43%;
  height: 90%;
  background-color: #4d4d4d;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: large;
  font-weight: 300;
  border-color: transparent;
}

.players-button {
  width: 100%;
  /* height: 6%; */
  background-color: #383838;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  box-sizing: border-box;
}

.buttonButton {
  width: 30%;
  height: 95%;
  background-color: #4d4d4d;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: small;
  font-weight: 300;
  border-color: transparent;
}

button:active {
  background-color: #2bb5e9;
  color: #282828;
}

.dropdown-toggle::after {
  display: none !important;
  border-radius: 0 !important;
}

.btn {
  border-radius: 0 !important;
  background-color: transparent !important;
}

.dropdown {
  background-color: transparent !important;
  border-radius: 0 !important;
  width: 100% !important;
  height: 100% !important;
  color: #2bb5e9 !important;
  border-color: #2bb5e9 !important;
}

.dropdown-toggle.btn.btn-secondary {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
  padding: 0.1rem !important;
  border-color: #4d4d4d !important;
  background-color: #4d4d4d !important;
}

.dropdown-menu {
  background-color: #4d4d4d !important;
  color: #2bb5e9 !important;
  border-radius: 0 !important;
}

.show.dropdown {
  border-radius: 0 !important;
  color: #2bb5e9 !important;
}

.btn-secondary {
  color: #2bb5e9 !important;
}

.dropdown-item {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgba(77, 77, 77, 1) !important;
  display: flex !important;
  margin: 0.2rem !important;
  width: auto !important;
}

.dropdown-menu.show {
  background-color: #323232 !important;
  padding: 0 !important;
}

.dropdown-item:hover {
  background-color: #383838 !important;
}

/* Active indicator (online/offline apps) */

.active-indicator-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-left: 0.1rem solid #282828;
  background-color: #383838;
}

.active-indicator-wrapper:last-child {
  border-right: 0.1rem solid #282828;
}

.active-indicator-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.active-indicator-title {
  margin: 0;
  padding-bottom: 0.2rem;
  color: white;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 0.8rem;
  text-align: center;
}

.active-indicator-value-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
}

.active-indicator-row {
  display: flex;
  flex-direction: row;
}

/* TRIANGLE shape inside action creator button */
.triangle {
  position: absolute;
  display: inline;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-color: transparent transparent #ff0000 transparent;
  border-width: 0 0 4vh 4vh;
  border-style: solid;
}

/* wrapper around content inside action button */
.action-button-content-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

/* override react-bootstrap css for guest/home tabs */
.tab-content {
  height: 100% !important;
}

.tab-pane.active {
  height: 100% !important;
}

.nav-link {
  border-radius: 0 !important;
  background-color: #4d4d4d !important;
  color: white !important;
  outline: none !important;
  border: 0 !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  padding-bottom: 0.6rem !important;
  padding-top: 0.6rem !important;
  margin-left: 0.2rem !important;
  margin-right: 0.2rem !important;
}

.nav-link:hover {
  outline: none !important;
  border: 0 !important;
}

.nav-link.active {
  background-color: #383838 !important;
  color: white !important;
  outline: none !important;
  border: 0 !important;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4d4d4d;
  border-radius: 5px;
  width: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* container for positioning items in navbar */
.navBar-content-container {
  display: flex;
  flex-direction: row;
}

.dropdown-menu.show.dropdown-menu-lg-end {
  min-width: none !important;
}
